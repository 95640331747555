<template>
    <div class="contain">
		<!-- <img v-if="defaultImg" style="width: 100%;" src="@/assets/images/default.jpg" alt=""> -->
        <!-- 搜索框 -->
        <div class="input-box">
            <!-- <a :href="newUrl">
                <img class="scan-code" src="@/assets/images/index/RichScan.png" alt="图片" > 
            </a> -->
            <!-- <img class="scan-code" src="@/assets/images/index/RichScan.png" alt="图片" @click="toScanPage"> -->
            <div class="search-input flex-wrap" @click="toSearchHistory">
                <input type="text" placeholder="请输入商品">
                <img src="@/assets/images/index/main-sousuo.png" alt="搜索">
            </div>
			
			<div class="scroll-h" v-if="searchKeys.length>0">
				<div class="scroll-wrapper">
					<span class="scroll-wrapper-i" v-for="item in searchKeys" @click="handleSearch(item.m_Item2)">{{item.m_Item2}}</span>
				</div>
			</div>
        </div>
        <!-- 图片轮播 -->
        <div class="swiper-box" v-if="bannerList.length > 0">
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                <van-swipe-item v-for="(item,index) in bannerList" :key="index">
                    <div class="item-img" :style="{height:item.Height/100 + 'rem'}" @click="skipPage(item.Url)">
                        <img :src="item.ImgUrl" alt="">
                    </div>
                </van-swipe-item>
            </van-swipe>
        </div>
        <!-- 导航 -->
        <div class="nav-list flex-wrap1 bor-bot2" v-if="navList.length>0"> 
            <div class="nav-item flex-column flex-center" v-for="(item,index) in navList" :key="index" @click="navSkip(item)">
                <img :src="item.ModulePic" alt="">
                <span class="item-text">{{item.Name}}</span>
            </div>
        </div>
        <!-- 公告 -->
        <div class="notice-box bor-bot2 flex-wrap" v-if="noticeList.length > 0">
            <div class="notice-list">
                <van-notice-bar left-icon="volume-o" background="#fff" :scrollable="false">
                    <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
                        <van-swipe-item v-for="(item,index) in noticeList" :key="index" @click="toNoticeDetail(item)">
                            {{item.Title}}
                        </van-swipe-item>
                    </van-swipe>
                </van-notice-bar>
            </div>
        </div>
		
		<!-- 新品推荐 -->
		<div class="new-swipe-box" v-if="newList.length>0">
			<van-swipe class="new-swipe" autoplay="" :show-indicators="false">
				<!-- <img src="@/assets/images/banner-new.png" /> -->
				<!-- <van-swipe-item v-for="item in newList" class="new-swipe-item" @click="handleRecommend()">
					<img :src="item.SectionPic" />
				</van-swipe-item> -->
				<van-swipe-item v-for="item in newList" class="new-swipe-item" @click="handleRecommend(item.Url)">
					<img :src="item.ImgUrl" />
				</van-swipe-item>
			</van-swipe>
		</div>
		
        <!-- 专区 -->
        <div  class="zone flex-wrap1 bor-bot2" v-if="zoneList.length>0">
           <div class="zoneItem" v-for="(item) in zoneList" :key="item.Id"  @click="navSkip(item)">
               <img :src="item.SectionPic" alt="">
           </div>
        </div>
		
		<!-- 海报宣传文案 -->
		<div class="tejia-box" v-if="false">
		    <div class="tejia flex-sp">
		        <div class="title ">宣传文案</div>
		        <div class="seck-more flex-wrap" @click="toPosterPage()"> 
					<span>更多</span>
					<img src="@/assets/images/icon_jiantou.png" alt="">
				</div>
		    </div>
			<div class="poster-type" style="display: flex; flex-wrap: wrap; padding: 0 10px;">
				<div class="poster-cell">热门</div><div class="poster-cell">感冒发烧</div>
				<div class="poster-cell">热门</div><div class="poster-cell">感冒发烧</div>
			</div>
			
			<div class="poster-list" style="display: flex; flex-wrap: wrap; padding: 10px;">
				<div class="poster-item">
					<img class="poster-img" src="@/assets/images/banner-new-.jpg" alt="" />
					<div class="poster-name">海报文案宣传图</div>
				</div>
				<div class="poster-item">
					<img class="poster-img" src="@/assets/images/banner-new-.jpg" alt="" />
					<div class="poster-name">海报文案宣传图</div>
				</div>
				<div class="poster-item">
					<img class="poster-img" src="@/assets/images/banner-new-.jpg" alt="" />
					<div class="poster-name">海报文案宣传图</div>
				</div>
			</div>
		</div>
		
        <!-- 秒杀专区 -->
        <div class="Seckill" v-if="SpikeFloor&&allTimeList.length>0">
            <div class="title" style="display: flex;justify-content: space-between;">
				<span>秒杀专区</span>
				<div class="Seckill-more" @click="toPage('/seckillList?headTit=seckill')">
				    <span>更多</span><img class="arrow" src="@/assets/images/index/mm.png" alt="">
				</div>
			 </div>
            <img :src="SpikeFloor.tit.FloorPic" alt="" @click="toPage(SpikeFloor.tit.Url)">
            <div class="Seckill-area ">
               <div class="Seckill-top flex">
                   <img src="../../assets/images/index/miaosha.jpg" alt="">
                   <div class="Seckill-heading">
                       <div class="font-w">热卖中</div>
                       <div class="font-hui">不能错过</div>
                   </div>
                   <div  class="Seckill-time " id="Seckill-time" >
                       <div class="Seckill-List" v-for="(timeItem,timeInd) in timeList" :key="timeInd" >
                        <div class="time-item " :class="timeInd==newIndex?'clickTime':''"  :name="timeInd=='0'?'item1':''"  :id="timeInd==newIndex?'scorllLeft':'' " @click="cutoverTime(timeItem.hour,timeInd)">
                                <div class="font-w " >{{timeItem.hour}}:00</div>
                                <div class="font-hui ">{{timeItem.title}}</div>
                        </div>
                       </div>
                   </div>
               </div>
               <div>
                     <seckillNew :list="timeShop" :active='activeTime' @toDetail='toDetail' @cartShow='cartShow' @onCartShow='onCartShow'></seckillNew>
               </div>
            </div>
        </div>
        <!-- 今日特价 -->
        <div class="tejia-box"  v-if="DailySpecial&&specialList.length>0">
           
            <div class="tejia flex-sp">
                <div class="title ">今日特价</div>
                <div class="seck-more flex-wrap" @click="toPage(DailySpecial.tit.Url)"> 
                        <span>更多</span>
                        <img src="@/assets/images/index/mm.png" alt="">
                    </div>
            </div>
            <img :src="DailySpecial.tit.FloorPic" alt="" @click="toPage(DailySpecial.tit.Url)">
               <div>
                     <seckillNew :list="specialList" :active=3 @toDetail='toDetail' @cartShow='cartShow' @onCartShow='onCartShow'></seckillNew>
               </div>
        </div>

        <!-- 商品 -->
        <div v-for="(floor,index) in floorArr" :key="index" v-show="isShow">
            <!--  近效期  团购  活动商品  集采-->
            <div class="seckill-box bor-bot2" v-if="floor.tit.Type == 30 && floor.list != undefined && floor.list.length > 0" >  
                <div class="seck-tit flex-sp">
                    <span class="title">{{floor.tit.Name}}</span>
                    <div class="seck-more flex-wrap" @click="toPage(floor.tit.Url)"> 
                        <span>更多</span>
                        <img src="@/assets/images/index/mm.png" alt="">
                    </div>
                </div>
                <div>
                    <img :src="floor.tit.FloorPic" alt="" @click="toPage(floor.tit.Url)">
                </div>
                <div class="seck-cont">
                    <div class="seck-list" :style="{height:floor.tit.Url=='/termValidity?headTit=nearExpiry'?'4.98rem':'4.4rem'}">
                        <div class="seck-absolute flex-wrap1" >
                            <div class="seck-item" v-for="(items,index) in floor.list" :key="index" @click="toActivityDetail(floor.tit.Url,items.GoodsId,items.Id)" :style="{height:floor.tit.Url=='/termValidity?headTit=nearExpiry'?'4.9rem':'4.3rem'}"> 
                                <div class="item-img">
                                    <img class="shop-img" v-if="items.ImgUrl" :src="items.ImgUrl" alt="商品图片">
                                    <img class="shop-img" v-else-if="!items.ImgUrl" src="@/assets/images/no_good.png" 
                                    onerror="javascript:this.src='https://goodssuffix.oss-cn-qingdao.aliyuncs.com/upload/c5c2ae08dbfb45898d64061a0d82e83e/9cc5bc0235044c54916fd18243082d10.png'">
                                     <div class="noShopImg"  v-if="items.kcshl<=0||items.Stock<=0"> 
                                         <span>售罄</span> 
                                     </div>
                                    <img v-if="floor.tit.Url!='/termValidity?headTit=nearExpiry' " class="bjt" src="../../assets/images/aa.png" alt="">
                                    <div class="item-setime flex-wrap" v-if="(floor.tit.Url=='/seckillList?headTit=seckill' || floor.tit.Url == '/seckillList?headTit=group' || floor.tit.Url=='/seckillList?headTit=purch') && items.timeStamp>0">
                                        <img src="@/assets/images/index/v2_ms_ico.png" alt="倒计时">
                                        <div class="settime-tit">{{items.timeStamp | timeFormat}}</div>
                                    </div>
                                </div>
                                <div class="item-info">
                                    <span class="info-name">{{items.GoodsName}}</span>
                                    <span class="info-specifi"  :class="items.GoodsStyle?'':'guige'">{{items.Style}}</span>
                                    <span class="info-specifi">{{items.GoodsStyle}}</span>
                                    <span class="info-company" >{{items.Factory}}</span>
                                    <span  v-if="floor.tit.Url=='/bargainPrice?headTit=bargain'" class="lastNum">仅剩<b class="font-red">{{items.kcshl}}</b></span>
                                    <span class="info-price font-red " :class="items.GoodsStyle?'':'jiage'" v-if="token&&items.Unit">{{(items.SoldPrice)}}/{{items.Unit}}</span>
                                    <span class="info-price font-red " :class="items.GoodsStyle?'':'jiage'" v-if="token&&!items.Unit">￥ {{(items.SoldPrice)}}</span>    
                                    <span class=" font-red" :class="items.GoodsStyle?'':'jiage'"  v-if="!token">资质通过后可见</span>
                                    <span class="info-old-price font-grey " :class="items.GoodsStyle?'':'yuanjia'" v-if="items.MarketPrice&&token">￥ {{(items.MarketPrice)}}</span>
                                    <span class="info-old-price font-grey " :class="items.GoodsStyle?'':'yuanjia'" v-if="!items.MarketPrice&&token">￥{{(items.OriginPrice)}}</span>                                
                                    <img class="shop-cart" :class="items.GoodsStyle?'carts':''" v-if="items.kcshl>0||items.Stock>0" @click.stop="onCartShow(items.GoodsId,floor.tit.Url,items)" :src="themeImgObj.cartImg" alt="">
                                    <img class="shop-cart" :class="items.GoodsStyle?'carts':''" v-else @click.stop="noCarShow" :src="themeImgObj.cartImg" alt="">
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <!-- 右箭头 -->
                    <!-- <img class="right-arrow" src="@/assets/images/index/icon_right.png" alt=""> -->
                </div>
            </div>


            <!-- type=20/10的样式 热销 20列表 10标签-->
            <div class="floor-box" v-else-if=" (floor.tit.Type == 10 || floor.tit.Type == 20) && floor.list !== undefined && floor.list.length>0 "> 			   
                <div v-if="!floor.tit.FloorPic || floor.tit.FloorPic.trim() === ''" class="floor-title flex-sp">
                    <span class="title">{{floor.tit.Name}}</span>
                    <div class="seck-more flex-wrap" @click="toPage(floor.tit.Url)">
                        <span>更多</span>
                        <img src="@/assets/images/icon_jiantou.png" alt="">
                    </div>                    
                </div>
                <!-- 活动图片 -->
                <div class="floor-floorpic" v-else><img :src="floor.tit.FloorPic" alt="" @click="toPage(floor.tit.Url)"></div>
                <div class="floor-list">
                    <shopList :list="floor.list" @toDetail='toDetail' @cartShow='cartShow' @onCartShow='onCartShow' @showSupplyDialog='showSupplyDialog'></shopList>
                </div>
            </div>
        </div>

        <!-- 猜你喜欢（推荐） -->
        <div class="floor-box" v-if="likeList.length>0 && isLike" style="display: none;"> 
            <div class="floor-title flex-sp">
                <span class="title">猜你喜欢</span>
            </div>
            <div class="floor-list">
                <shopList :list="likeList" @toDetail='toDetail' @cartShow='cartShow' @onCartShow='onCartShow' @showSupplyDialog='showSupplyDialog'></shopList>
            </div>
        </div>
        
        <!-- 活动弹窗-->
        <div class="popup-money" v-if="popLayerShow" @click="closePopLayer()"></div>
        <div class="popup-cont-layer" v-if="popLayerShow">
            <img class="popup-img" :src="popupInfo.Pic" alt="红包图片" @click="topopPage()">
            <img class="popup-close" src="@/assets/images/index/icon_close.png" @click.stop="closePopLayer" alt="关闭">
        </div>
        <!-- 签到弹窗 -->
        <div class="sign-pop-box" v-if="signShow"></div>
        <div class="sign-pop" v-if="signShow">
          <div class="sign-top flex-wrap">
            <span class="top-tit">每日签到</span>
            <span class="top-close" @click="closeSign">x</span>
          </div>
          <div class="sign-box">
            <div class="sign-tit">每日签到领积分，积分可兑换卡券或好物</div>
            <div class="sign-day flex-sp">
              <div class="day-item" v-for="(item,index) in signData.SignList" :key="index">
                <div class="item-box flex-column" :class="item.IsToday?'active':''"  >
                  <span class="item-num">{{item.WeekDay}}</span>
                  <img class="item-img" v-if="item.IsSign" src="@/assets/images/mine/signin_do.png" alt="">
                  <img class="item-img" v-else src="@/assets/images/mine/signin_did.png" alt="">
                </div>
                <span class="item-d">{{item.IsToday?"今天":item.WeekDate}}</span>
              </div>
            </div>
			
			<div style="display: flex; justify-content: center; padding: 0 20px;">
				<div style="flex: 1;">
					<div class="sign-t">已连续签到<b class="sign-blue" >{{signData.ContinueDay}}</b>天</div>
					<div class="sign-point flex-center">
					  <img class="pImg" src="@/assets/images/mine/singin_points.png" alt=""> 
					  <span class="sign-blue" style="padding-left: 0.1rem;"> +{{signData.TodaySignInPoints}}</span>
					</div>
				</div>
				<div style="flex: 1;">
					<div class="sign-t">已获取礼品 <b class="sign-blue" >{{signData.giftCount}}</b></div>
					<div class="sign-point flex-center">
					  <img class="gImg" :src="signData.GiftPic" alt=""> 
					  <span class="sign-blue">{{signData.GiftName}} +1</span>
					</div>
				</div>
			</div>
			
            
            <div class="sign-btn sign-blue" v-if="!signData.IsSignIn" @click="onSignTap">签到</div>
            <div class="sign-btn sign-grey" v-if="signData.IsSignIn" >已签到</div>
          </div>
        </div>

        <!-- 加载样式 -->
        <div class="loading-box flex-center" v-if="showLoading && likeList.length>0">
            <img class="load-img" src="@/assets/images/index/loading.gif" alt="加载图片">
            <span class="load-txt">正在加载</span>
        </div>
        <div class="loading-box flex-center" v-if="noMoretip && likeList.length>5">
            <span class="load-txt">已经到底了！</span>
        </div>
		
		<!-- 一个楼层更多时加载样式 -->
		<div class="loading-box flex-center" v-if="isOneFloor && oneFloorItem.ShowLoading">
		    <img class="load-img" src="@/assets/images/index/loading.gif" alt="加载图片">
		    <span class="load-txt">正在加载</span>
		</div>
		<div class="loading-box flex-center" v-if="isOneFloor && oneFloorItem.NoMore">
		    <span class="load-txt">已经到底了！</span>
		</div>		
        <!-- 活动商品购物车弹窗 -->
        <active-cart :cartNums='cartNum' :cartShowPop="activeCartShowPop"  :goodSpid="goodSpid" :goodDetail="goodDetail" :typePage="typePage" @diffCartShow="closeCartActive"></active-cart>
        <!-- 普通商品购物车弹窗 -->
        <cart-pop :cartShowPop="cartShowPop" :goodInfo="goodInfo" @diffCartShow="closeCart"></cart-pop>
        <!-- 多规格弹窗 -->
        <specification  :isSpecification='isSpecification'  @isGuiGeShow="closeGuiGe" :guiGeDetail="guiGeDetail" :guiGeDes='guiGeDes' :bottomHight='bottomHight'/>
        
		
		<!-- 缺货登记弹窗 -->
		<div class="register-popup" v-if="showSupplyStock"></div>
		<div class="register-cont" v-if="showSupplyStock">
		  <div class="regis-tit">缺货登记</div>
		  <div class="regis-item flex-wrap">
		    <span class="item-tit">商品名称：</span>
		    <div class="input-s"><input type="text" disabled v-model="supplyInfo.spmch" /></div>
		  </div>
		  <div class="regis-item flex-wrap">
		    <span class="item-tit">商品规格：</span>
		    <div class="input-s"><input type="text" disabled v-model="supplyInfo.shpgg" /></div>
		  </div>
		  <div class="regis-item flex-wrap">
		    <span class="item-tit">生产厂商：</span>
		    <div class="input-s">
		      <input type="text" disabled v-model="supplyInfo.shengccj" />
		    </div>
		  </div>
		  <div class="regis-item flex-wrap">
		    <span class="item-tit">需求数量：</span>
		    <div class="input-s">
		      <input type="number" v-model="supplyNum" placeholder="请输入需求数量"/>
		    </div>
		  </div>
		  <div class="regis-item flex-wrap">
		    <span class="item-tit">最长等待时间：</span>
		    <div class="input-s flex-wrap">
		      <input type="number" v-model="waitDay" placeholder="请输入等待天数" />
		      <span class="input-tit">天</span>
		    </div>
		  </div>
		  <div class="regis-but flex-wrap1">
		    <div class="but-around grey" @click="showSupplyStock=false">取消</div>
		    <div class="but-around blue" @click="onSupplyStock">确定</div>
		  </div>
		</div>
    </div>
</template>

<script>
import registerRequest from "@/assets/js/api/register.js";
import shopList from "../../components/shopList.vue"
import seckillNew from "../../components/seckillNew.vue"
// 引入活动弹窗组件
import activeCart from '../../components/activeCartPop.vue';
import cartPop from "../../components/cartPop.vue";
// 引入跳转活动详情的js
import jumpActivity from "../../assets/js/toActivityPage";
import specification from '../../components/specification.vue';
import Vue from 'vue';
import { Tab, Tabs, Toast, Dialog} from 'vant';
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Dialog);
export default {
    components: {
        activeCart,cartPop,specification,seckillNew,shopList
    },
    data(){
        return{
			searchKeys: [],
            moneyPopup: true,
            moneyPopup2: false,
            bannerList: [],   // 图片轮播
            navList: [],    // 导航
            floorList: [],  // 楼层
            noticeList: [],  // 公告
            popupInfo: {},  // 弹窗信息
            popLayerShow: false,    // 弹窗显示隐藏  false隐藏   true显示
            floorArr: [],   // 全部楼层数据
            showLoading: true,  // 加载更多
            likeList: [],   // 猜你喜欢（为您推荐）
            page: 1,
            noMoretip: false, 
            storeyList:[],  // type == 20或者 type==10 的楼层数据
            isShow: true,   // 数据重新渲染
            isLike: true,   // 猜你喜欢数据渲染
            cartShowPop: false,        // 商品弹窗
            goodsInfo: {},      // 购物车弹窗详情
            cartNum: 1,         // 购物车数量
            selectId: '',       // 选中活动的id
            editNumShow: false,     // 编辑数字弹窗显示隐藏
            cartEditNum:1,          // 编辑弹窗输入框中的值
            zoneList:[],//专区
            ticker: null,
            goodInfo: {}, // 向组件传递商品详情(普通弹窗)
            activeCartShowPop: false,     // 组件显示隐藏
            goodSpid: '',         // 向组件传递的spid
            goodDetail: {},     // 向组件传递商品详情
            typePage:'',    // 向组件传递页面类型
            isBack: true,   //  为true可以返回上一个页面     
            newUrl: '', // 扫码路径
            ninNum: "", //做少添加
            maxNum: "", //最多添加
            token : sessionStorage.getItem('member_token'),
            isSpecification:false,//规格
            guiGeDetail :{},//规格详情
            guiGeDes:{},//规格
            bottomHight:true,
            activityHight:false,
            active:1,
            newIndex:0,
            timeShop:[],
            timeList:[],
            allTimeList:[],
            activeTime:0,
            SpikeFloor:null,//秒杀楼层
            DailySpecial:null,//今日特价楼层
            specialList:[],//今日特价商品
            wxOpenid: '',     // 公众号或者小程序
            fromPath: '',       // 从哪个页面过来的
			defaultImg: false,
			
			androidFlag: false,//是否Android 浏览器
			newList: [],//新品推荐
			keyId: "", //埋点 主键
			
			signData:{},  // 签到数据
			signShow: false,    //是否显示签到弹窗 false隐藏  true显示
			
			showSupplyStock: false, //缺货登记弹窗  false隐藏  true显示
			supplyInfo: null,
			supplyNum: "", // 缺货登记需求数量
			waitDay: "", // 缺货登记等待天数
		    isOneFloor:false,//只有一个楼层，可以上拉加载				
			oneFloorItem:{	
				ShowLoading:false,
				NoMore:false,
				Page:1,
				Args:"",
				FloorId:"",			
				Id:"",			
				Name:"",
				Type: 10,			
				// OrgCode: "011901"
				// OrgId: "2679591e77624a6489912571f449832c"	
				// Url: "/allProducts?type=allProducts&tag=%u534E%u590F%u4EF2%u666F"
				// UrlArgs: ''
			},
        }
    },
    created(){
		// this.androidFlag = /(Android)/i.test(navigator.userAgent.toLowerCase());
		// 获取设备类型 app/小程序(applets)/公众号(weixin)
		let clientType = localStorage.getItem("clientType");
		if(clientType == "app"){
			this.androidFlag = true;
		}else if(clientType == "ios"){
			// ios  todo
		}
		//获取品牌信息， 搜索关键词
		this.getCompanyLogo();
    },
    mounted(){
        //这一段是防止进入页面出去后再进来计时器重复启动
        if (this.ticker) {
            clearInterval(this.ticker);//时间
        }
        this.chronography();
        window.addEventListener('scroll', this.onReachBottom);
        var that = this;
        that.isBack = that.$route.meta.isBack;
        if (window.history && window.history.pushState) {
            history.pushState(null, null, document.URL);
            window.addEventListener('popstate', that.popstateBack,true);
        }
      this.hourlyRefresh(function (hour) {
        // console.log('执行整点计划 =>', hour);
        that.getSeckillList();
        });
      if(!localStorage.orgId){
            localStorage.orgId=that.public.getConfig.defaultOrgId;
        }
        
    },
    beforeRouteEnter(to, from, next) {
        var that = this;
        if (from.path == '/login' || from.path == '/' || from.path == '/classifcation'||from.path == '/search' || from.path == '/cart' || from.path == '/mine' || to.query.refresh == 'refresh'||   from.path ==  '/pointsMall'|| from.path ==  '/roundTable'|| from.path ==  '/orderSuccess'|| from.path ==  '/selectCompany'|| from.path ==  '/purchaseOrder') { // 这个name是登录页面的路由path    
            to.meta.isRefresh = false;    // 刷新
        }else if(from.path != '/login'){
            to.meta.isRefresh = true;   // 设置为true说明是返回到这个页面，而不是通过跳转从其他页面进入到这个页面
        };
        next((vm) => {
            // 通过 `vm` 访问组件实例,将值传入fromPath
            vm.fromPath = from.path;
        });
    },
    activated(){
		// console.log("回到首页")
		var that = this;
		if(this.androidFlag && top.android){
			top.android.onGoBack(true); //调用app方法
		}
		//统计分析 埋点进来
		this.keyId = ""; //每次进入置空
		this.public.setBuryPoint(1,1,this.keyId).then(res=>{
			if(res.errorCode=="00"){
				that.keyId = res.rows;
			}
		});
        // 主题色图片
        that.themeImgObj = that.themeColorImg.imgObj;
		
		// 小程序
		// that.AppletLogin("18d2a0f4b3b5161040c36419ca6dafb7");
		if(location.href.indexOf('?') != -1){
			var params=that.public.urlObj(location.href);
			if(params.jumid){
				that.AppletLogin(params.jumid);
			}
		}
        var IsSpecial = sessionStorage.getItem("IsSpecial");
        if(!IsSpecial){
            // 登录状态
            that.isLogin();
        }else{
			that.searchKeys = [];
            // 猜你喜欢
            that.bannerList = [],   // 图片轮播
            that.navList = [],    // 导航
            that.floorArr = [],  // 楼层
            that.noticeList = [],  // 公告
            that.allTimeList = [],  // 秒杀
            that.zoneList = [],     // 专区
            that.specialList=[],//今日特价
            that.page=1;
            that.guessLike();
        }
        
        window.addEventListener('scroll', this.onReachBottom);
        var refresh = that.$route.query.refresh;
        if(refresh == 'refresh'){
            let path = that.$route.path; //先获取路由路径
            that.$router.replace(path);
        }
        var that = this;
        var url = location.href;
        var ishttps = 'https:' == document.location.protocol ? true: false;
        if(ishttps){
            that.newUrl = 'http://sao315.com/w/api/saoyisao/?redirect_uri=' + url;
        }else{
            that.newUrl = 'http://sao315.com/w/api/saoyisao?redirect_url='+url;
        }
        var ua = window.navigator.userAgent.toLowerCase();
         if(ua.match(/MicroMessenger/i) == 'micromessenger'){
            that.onWxScan();
         }else{
         }
        that.isBack = that.$route.meta.isBack;
        if (window.history && window.history.pushState) {
            history.pushState(null, null, document.URL);
            window.addEventListener('popstate', that.popstateBack,true);
        }
    },
    deactivated(){
		// console.log("离开首页")
		if(this.androidFlag && top.android){
			top.android.onGoBack(false); //调用app方法
		}
		//埋点离开
		this.public.setBuryPoint(1,2,this.keyId).then(res=>{
			if(res.errorCode=="00"){}
		});
        window.removeEventListener('scroll', this.onReachBottom);
        window.removeEventListener('popstate',this.popstateBack,true);
    },
	
	destroyed(){
	    clearInterval(this.ticker);
	    window.removeEventListener('scroll', this.onReachBottom);
	    window.removeEventListener('popstate',this.popstateBack,true);
	},
    methods:{
		// 获取logo信息
		getCompanyLogo() {
		  var that = this;
		  that.public.request("get","/api/SysOrgInfo/QueryOrgWebConfig",{
		      orgId: localStorage.getItem("orgId"),
			},(res) => {
		      if (res.data.errorCode == "00") {
				  let stepStock = res.data.rows.ShowSetpStock;
				  localStorage.setItem("ShowStock", stepStock?stepStock:false);
				  
				  that.searchKeys = res.data.rows.keyWord;
		      }else{
		        // that.$toast(res.data.errorMsg);
		      }
		    }
		  );
		},
		
		//缺货登记补充弹窗
		showSupplyDialog(goodInfo){
			this.supplyInfo = goodInfo;
			this.showSupplyStock = true;
		},
		
		// 缺货登记弹窗确定
		onSupplyStock() {
		  var that = this;
		  if (!that.supplyNum) {
		    that.$toast("请输入需求数量");
		    return;
		  }
		  if (!that.waitDay) {
		    that.$toast("请输入等待天数");
		    return;
		  }
		  var reg = /(^[1-9]\d*$)/;
		  if (!reg.test(that.supplyNum)) {
		    that.$toast("请输入大于0的整数");
		    return;
		  }
		  if (!reg.test(that.waitDay)) {
		    that.$toast("请输入大于0的整数");
		    return;
		  }
		
		  registerRequest.saveGoodsStockOutByDay({
		    spid: that.supplyInfo.spid,
		    goodsCount: that.supplyNum,
		    waitDays: that.waitDay,
			ReceiveId: localStorage.getItem('orgId')
		  }, (res) => {
		    if (res.data.errorCode == "00") {
		      that.$toast("登记成功");
		      that.showSupplyStock = false;
		    } else {
		      that.$toast(res.data.errorMsg);
		    }
		  });
		},
		
		//添加记录浏览量
		createHistory(goodSpid){
		  this.public.request("post","api/GoodHistory/CreateHistory",{
			  SpId: goodSpid, //（商品id）
			  CustomId: localStorage.getItem("orgId")  //（登陆客户orgid）
		  },res=>{
			  if(res.data.errorCode=="00"){
				// console.log("浏览记录成功!")
			  }else{
				console.log("浏览记录失败~")
			  }
		  })
		},
		/**
		 * 推荐搜索
		 */
        handleSearch(keyword){
			this.$router.push({
			    path: '/search',
			    query: {
			        keyword: keyword,
			    }
			})
		},
        // 扫码获得的参数
        onWxScan(){
            var that = this;
            var code = this.$route.query.qrresult;
            if(!code){
                return false;
            }
            var index=code.indexOf(",");
            if(index < 0){
                that.$toast('请描扫条形码');
                return false;
            }
            history.pushState(null, null, document.URL);
            that.$router.replace({
                path: '/allProducts',
                query: {
                    headTit: '全部商品',
                    code: code,
                }
            });
            
        },
        allRequest(){
            var that = this;
            // 查看用户是否登录
            that.token= sessionStorage.getItem('member_token');
            //   console.log(that.$route.meta.isRefresh);
            if(that.$route.meta.isRefresh==false){//如果是false,刷新
                // console.log("首页刷新");
                // 图片轮播
                that.banner();
				that.recommendBanner();
                // 弹窗信息
				if(localStorage.getItem("isPush")=="true"){
					that.getPopupInfo();
				}
                // 公告
                that.getNotice();
                // 猜你喜欢
                that.page=1
                that.guessLike();
                // 导航接口、楼层
                that.getNavList();
                that.token= sessionStorage.getItem('member_token');
                // 购物车数量
                if( that.token){
                        that.public.getCartNum();
                }
                  this.getSeckillList();
                  this.newIndex=0;
            }
        },

        // 登录状态
        isLogin(){
            /* 
                判断当前是否为小程序或者公众号
                不是从登录也过来的获取小程序或者公众号的openid
                通过获取code，请求新的token，赋值到sessionStorage
                // 验证登录状态
                1.如果用户登录了，没有退出，结束了进程，下次进来依然是登录状态
                2.用户没有退出进程，则不请求登录接口
            */
            var that = this;
            var clientType = localStorage.getItem("clientType");
            var token = sessionStorage.getItem("member_token");
            var mobile = localStorage.getItem("mobile");
            var password = localStorage.getItem("password");
			
            if(!token){     // 用户未登录(直接进入首页)，游客模式
                if(mobile && password){  // 账号密码登录，用户之前登录过（手机清除了后台运行）
                    // sessionStorage.setItem("junp",true);
                    mobile = that.public.decrypt(mobile);
                    password = that.public.decrypt(password);
                    if(clientType == 'weixin'){
                        if(that.fromPath != '/login'){
                            that.public.getopenId();
                        }
                    }
                    that.getLogin(mobile,password);
                    
                }else if(mobile && !password){   // 手机短信验证登录
                    if(clientType == 'weixin'){     // 微信公众号端
                        var provKey = localStorage.getItem("provKey");
                        // // 如果不是从登录页面过来的，没有token，则请求新的token 
                        if(that.fromPath != '/login'){
                            that.public.getopenId();
                        }
                        that.getCodeLogin(provKey);
                    }
                    
                }else if(!mobile && !password){    // 用户没有登录过
                    that.allRequest();
                }
            }else{   // 有token，app在后台运行
                // sessionStorage.setItem("junp",true);
                that.allRequest();
                
            }
        },
        // 短信验证登录通过key获取账号，获取token
        getCodeLogin(provKey){
            var that = this;
            var data = {
                Mobile: provKey,
            }
            that.public.request('post','/api/AppLogin/GetAppletLogin',data,(res => {
                if(res.data.errorCode == '00'){
                    sessionStorage.setItem("member_token",res.data.rows);
                    that.allRequest();
                }else{
                    that.allRequest();
                }
            }))
        },
        
        //  重新登录 获取token
        getLogin(mobile,password){
            var that = this;
            var orgId = localStorage.getItem("orgId");
            var clientType = localStorage.getItem("clientType");
            // var Applets = localStorage.getItem("Applets");
            var Applets = that.$route.query.openid;
            var data = {
                Mobile: mobile,
                Password: password,
                OrgId: orgId,
            }
            // console.log(data);
            // 获取微信小程序的openid
            if (clientType == "applets" && Applets) {
                data.Applets = Applets;
            }
            // 微信公众号openid
            that.wxOpenid = localStorage.getItem("openid");
            if(clientType == 'weixin' && that.wxOpenid){
                data.openid = that.wxOpenid;
            }

            that.public.request("post","/api/AppLogin/Login",data, (res) => {
                if (res.data.errorCode == "00") {
                    sessionStorage.setItem("member_token",res.data.rows);
                    localStorage.setItem("orgId", res.data.errorMsg);
                    sessionStorage.setItem("customer",res.data.extend.customerId);
                    localStorage.setItem("clientType", clientType); // app类型
                    var webconfig = JSON.stringify(res.data.extend.webConfig);
                    localStorage.setItem("webconfig", webconfig); 
                    // 请求首页全部数据
                    that.allRequest();

                }else if(res.data.errorCode == '99'){
                    that.allRequest();
                }
            });
        },
       hourlyRefresh (callback) {
        var date, hour, minute, second, readyHour;
        setInterval(function () {
            date = new Date()
            hour = date.getHours();
            minute = date.getMinutes();
            second = date.getSeconds();
            // console.log('当前时间:' + hour + ':' + minute + ':' + second)    
            if(minute === 0) {
				if(!readyHour) {
					readyHour = true;
					callback(hour);
				}
            } else {
				readyHour = false;
            }
        }, 1000)
       },
        // 首页弹窗跳转页面
        popupJumpPage(){
            var that = this;
            // that.$router.push({
            //     path: '/redPackPain.vue'
            // })
        },
		
		//判断是否小程序浏览器加载
		isMini: function(){
			let mini = false;
			if (/MicroMessenger/i.test(navigator.userAgent)) {
				//ios的ua中无miniProgram，很坑爹,但都有MicroMessenger（表示是微信浏览器）
				this.wx.miniProgram.getEnv((res) => {
					if (res.miniprogram) {
						mini = true;
					}
				});
			}
			return mini;
		},

        // 活动商品弹窗显示
        onCartShow(spid,url,goodInfo,id){
			//统计分析 埋点进来
			this.public.setBuryPoint(5,1,this.keyId).then(res=>{});
			// console.log("======活动商品======");
            var that = this; 
              // 查看是否登录     
             if(that.token == null){
                 var orgId = localStorage.getItem("orgId");
                 that.$dialog.confirm({
                         message: "你还未登录，是否登录!",
                     }).then(() => {
						 
						if(that.isMini()){
						 	that.wx.miniProgram.reLaunch({
						 	   url: window.baseUrl.xcxLogin?'/pages/login/index?logout=true':'/pages/login/oldLogin/index?logout=true',
						 	})
						 	return;
						}
						 
                        that.$router.push({
                             path: '/login',
                         query: {
                             orgId: orgId,
                             auto: 0,
                         }
						});
                     }).catch(() => {
 
                     });
                    return false;
                }
                if(id=='1'){
				   that.typePage = 'seckill'; 
				   that.goodDetail = goodInfo;
				   that.activeCartShowPop = true;
                    return that.goodSpid = goodInfo.GoodsId;
                }
                 if(id=='3'){
				   that.typePage = 'bargain'; 
				   that.goodDetail = goodInfo;
				   that.activeCartShowPop = true;
                    return that.goodSpid = goodInfo.GoodsId;
                }
            url = url.toLowerCase();
            that.activeCartShowPop = true;
            if(url == '/termvalidity?headtit=nearexpiry'){    // 近效期
                    that.typePage = 'nearExpiry';
            }else if(url == '/seckilllist?headtit=group'){    // 团购
                that.typePage = 'group';                    
            }else if(url == '/bargainprice?headtit=bargain' || '/dailyspecial/detail'){    // 今日特价
                that.typePage = 'bargain';
            }else if(url == '/seckilllist?headtit=purch'){    // 集采
                that.typePage = 'purch';
            }else if(url == '/seckilllist?headtit=seckill'){    // 秒杀
                that.typePage = 'seckill';                   
            }else if(url == ''){    // 列表中的活动商品
                var ActivityType = goodInfo.CurActivityData.ActivityType;
                if(ActivityType == 0){  // 团购
                    that.typePage = 'group'; 
                }else if(ActivityType == 1){   // 秒杀
                    that.typePage = 'seckill';     
                }else if(ActivityType == 2){    // 今日特价
                    that.typePage = 'bargain';
                }else if(ActivityType == 3){    // 集采
                    that.typePage = 'purch';
                }
            }
            that.goodDetail = goodInfo;
            that.goodSpid = spid;
			// console.log("=======url=======",url);
   //          console.log("==============",that.typePage);
			//浏览记录
			// that.createHistory(spid);
            
        },
        // 活动商品弹窗隐藏
        closeCartActive(){
            var that = this;
            that.activeCartShowPop = false;
        },
       //关闭规格弹窗
        closeGuiGe() {
			var that = this;
			that.isSpecification = false;
        },

        // 普通商品购物车弹窗显示
        cartShow(spid,goodInfo,id) {
			// console.log("======普通商品======");
			//统计分析 埋点进来
			this.public.setBuryPoint(5,1,this.keyId).then(res=>{});
            var that = this;
            // 查看是否登录
            var token = sessionStorage.getItem('member_token');
            if (token == null) {
                var orgId = localStorage.getItem("orgId");
                that.$dialog.confirm({
                    message: "你还未登录，是否登录!",
                }).then(() => {
					if(that.isMini()){
					 	that.wx.miniProgram.reLaunch({
					 	    url: window.baseUrl.xcxLogin?'/pages/login/index?logout=true':'/pages/login/oldLogin/index?logout=true',
					 	})
					 	return;
					}
                    that.$router.push({
						path: "/login",
						query: {
							orgId: orgId,
							auto: 0,
						},
                    });
				}).catch(() => {
                });
                return false;
            }
            if(id=='0'){
               return that.$toast('活动还没开启');
            }
           if((JSON.stringify(goodInfo.StyleGroup)!='{}'&&goodInfo.StyleGroup!=null)){
            that.isSpecification=true;
            var guiges=goodInfo.StyleGroup;
            Object.keys(guiges).forEach(key=>{
                 if( guiges[key].ActivityCacheDataList.length>0){
                    let item1='ActivityId';
                    let value1=guiges[key].ActivityCacheDataList[0].ActivityId;
                    guiges[key][item1]=value1;
                    let item2='Description';
                    let value2=guiges[key].ActivityCacheDataList[0].Description;
                    guiges[key][item2]=value2;
                    let item3='ActivityType';
                    let value3=guiges[key].ActivityCacheDataList[0].ActivityType;
                    guiges[key][item3]=value3;
                 }
                let item='num';
                let value=0;
            guiges[key][item]=value;
            });
            that.guiGeDes=guiges;
            // console.log(that.guiGeDes);
            that.guiGeDetail = goodInfo;
           }else{
            //    console.log(22);
				that.cartShowPop = true;
				that.goodInfo = goodInfo;
           }
		   
		   //浏览记录
		   // that.createHistory(goodInfo.spid);
        },

        // 关闭组件弹窗
        closeCart() {
            var that = this;
            that.cartShowPop = false;
        },
        // 切换时间
        cutoverTime( time,id){
            var that=this;
            that.newIndex=id;
            that.activeTime=0;
            var element=document.getElementById('Seckill-time');
            // var aa=document.getElementsByName('item1')[0].offsetLeft;
            var item=document.getElementById('scorllLeft').clientWidth+6;//每一个大小
            id=id-1;
            item= item*id;//需要移动的大小
            element.scrollLeft = item;
            that.timeShop=[];
            that.allTimeList.forEach(item=>{
                if(item.hour==time){
                    that.timeShop=item.list;
                    if(item.title=='正在秒杀'){
                    that.activeTime=1;
                     }
                }
            });
            // console.log( that.timeShop);
        },
        //这个计时器是每秒减去数组中指定字段的时间
        chronography(){
            var that = this;
            that.ticker = setInterval(() => {
                for(var i=0;i<that.floorArr.length;i++){
                    if(that.floorArr[i].list){
                        for(var j=0; j<that.floorArr[i].list.length;j++){
                            if(that.floorArr[i].list[j].timeStamp > 0){
                                that.floorArr[i].list[j].timeStamp = that.floorArr[i].list[j].timeStamp - 1;
                                that.$forceUpdate();
                            }
                        }
                    }
                    
                }
            }, 1000);
            
        },
       
        
        // 关闭弹窗(炸屏)
        closePopLayer(){
            this.popLayerShow = false;
        },
        // 弹窗详情（炸屏）
        topopPage(){
            var that = this;
            that.popLayerShow = false;
            var popupInfo = that.popupInfo;
            var url = popupInfo.Url;
            // 判断路由是否携带参数  不带参数
            if(url.indexOf('?') == -1){
                that.$router.push({
                    path: url,
                });
            }else{
                var urlObj = that.public.urlObj(url);
                var urlPage = url.split("?")[0];
                var query = {};
                for(var key in urlObj){
                    query[key] = urlObj[key];
                }
                that.$router.push({
                    path: urlPage,
                    query: query,
                })
            }

        },


        // 去扫码页面
        toScanPage(){
            var that = this;
            that.$router.push({
                path: '/scan'
            });
        },
        noCarShow(){

        } ,

        // 图片轮播
        banner(){
            var that = this;
            var orgId = localStorage.getItem('orgId');
            var data = {
                OrgId: orgId,
                Code: '20001',
                AdvertPlatformType: 20,
            }
            that.public.request('post','/api/advertItem/queryAdvertItem',data,res => {
                if(res.data.errorCode == '00'){
                    that.bannerList = res.data.rows;
                }
            })
        },
		
		// 首发创新广告位
		recommendBanner(){
		    var that = this;
		    var orgId = localStorage.getItem('orgId');
		    that.public.request('post','/api/advertItem/queryAdvertItem',{
				OrgId: orgId,
				Code: '20015',
				AdvertPlatformType: 20,
			},res => {
		        if(res.data.errorCode == '00'){
		            that.newList = res.data.rows;
		        }
		    })
		},
		
        // 点击图片轮播跳转页面
        skipPage(url){
            var that = this;
            if(url==null){//处理后台没有设置，报错问题
                return false;
            }
            // 判断路由是否携带参数  不带参数
            if(url.indexOf('?') == -1){
                that.$router.push({
                    path: url,
                });
            }else{
                var urlObj = that.public.urlObj(url);
                var urlPage = url.split("?")[0];
                var query = {};
                for(var key in urlObj){
                    query[key] = urlObj[key];
                }
                that.$router.push({
                    path: urlPage,
                    query: query,
                })
            }
            
            
        },
        // 导航接口、楼层
        getNavList(){
            var that = this;
             that.SpikeFloor=null;
             that.DailySpecial=null;
            var data = {
                AppVersion: 20,
            }
            that.$toast.loading({
                message: '加载中...',
                duration: 0,
            })
            that.public.request('get','/api/IndexModuleApp/QueryIndexModuleApp',data,res => {  // 
     
                that.$toast.clear();
                if(res.data.errorCode == '00'){
					// that.newList = res.data.rows.ListNew;
                    // console.log('楼层-----',res);
                    that.zoneList= res.data.rows.ListSection;
                    // 导航
                    that.navList = res.data.rows.ListModule;
                    // 楼层
                    var floorList = res.data.rows.ListFloor;
					if(floorList && floorList.length==1){						
						that.setOneFloorItem(floorList[0]);											
					}
                    var arr = [];
                    // 解析楼层路由
                    for(var i=0; i<floorList.length; i++){
                        arr.push({tit:floorList[i]});
                    }
                    that.floorList = arr;
					that.sendRequests(that.floorList);
					// return;
                    // console.log("====楼层====",JSON.stringify(arr));
                    that.floorList.forEach((item,index) => {
						// if(index==0){
						// 	that.getFloorList(item.tit.FloorId,item.tit.Id);
						// }
						// return
                        // 标签(楼层列表)
                        // if(item.tit.Type == "10"){
                        //     that.getGoodsList(item.tit.Id,item.tit.Args,item.tit.Id);
                        // }
                        // 列表（楼层列表）
                        if(item.tit.Type == "20"){
                            if(item.tit.FloorId){
                                that.getFloorList(item.tit.FloorId,item.tit.Id);
                            }
                        }
						
                        // 秒杀、团购、近效期、今日特价、集采
                        if(item.tit.Type == "30"){
                            if(item.tit.UrlArgs == "秒杀"){
                                that.SpikeFloor=item;
                                that.getSeckill(item.tit.Id);
                            }else if(item.tit.UrlArgs == "团购"){
                                that.getgroupBuy(item.tit.Id,10);
                            }else if(item.tit.UrlArgs == "近效期"){
                                that.getNearTerm(item.tit.Id);
                            }else if(item.tit.UrlArgs == "今日特价"){
                                that.DailySpecial=item;
                                that.getDailySpecial(item.tit.Id);
                            }else if(item.tit.UrlArgs == "集采"){
                                that.getgroupBuy(item.tit.Id,20);
                            }
                        }
                    })
                    
                }
            })

        },
		
		/**
		 * 队列请求
		 * @param {Object} floorList 楼层分类（请求数量）
		 */
		async sendRequests(floorList) {
		  let that = this;
		  for (var i = 0; i < floorList.length; i++) {
		  	const item = floorList[i];
			if(item.tit.Type == "10"){
				await that.sendRequest(item.tit.Id,item.tit.Args,item.tit.Id).then(res=>{
					if(res.errorCode == '00'){
					    for(var i=0; i<that.floorList.length; i++){
					        if(that.floorList[i].tit.Id == item.tit.Id){
					            that.floorList[i].list = res.rows;
					        }
					    }
					    that.floorArr = that.floorList;
					    // console.log('楼层',that.floorList);
					    that.floorArr.forEach(item=>{
					        if(item.tit.Type!='30'&&item.list){
					            item.list.forEach(item2=>{
					                var list=[];
					                item2.ActivityCacheDataList.forEach(item3=>{
					                    list.push(item3.ActivityTypeName);
					                });
					                const set = new Set(list);
					                item2.activeList=set;
					            })
					        }
					    });
					    that.isShow = false;
					    that.$nextTick(()=>{
					        that.isShow = true;
					    })
					}
				})
			}
		  }
		},	
		
		sendRequest(id,tag,itemId,page) {
			var that = this;
			let tempPage=1;
			if(!!page && page>1){
				tempPage=page;
			}
		  // 模拟请求函数，使用Promise
		  return new Promise((resolve, reject) => {
			// setTimeout(() => {
				that.public.request('post','/api/indexModule/queryGoodsList',{
					page:tempPage,
					Id: id,
					Tag: tag,
					ItemId: itemId,
					rows:6,
				},res => {
					resolve(res.data);
				});
			// }, 500);
		  });
		},

        // 楼层列表 type==10 的数据
        getGoodsList(id,tag,itemId){
            var that = this;
            var data = {
                Id: id,
                Tag: tag,
                rows:6,
                ItemId: itemId,
            };
            that.public.request('post','/api/indexModule/queryGoodsList',data,res => {
                if(res.data.errorCode == '00'){
                    for(var i=0; i<that.floorList.length; i++){
                        if(that.floorList[i].tit.Id == id){
                            that.floorList[i].list = res.data.rows;
                        }
                    }
                    that.floorArr = that.floorList;
                    // console.log('楼层',that.floorList);
                    that.floorArr.forEach(item=>{
                        if(item.tit.Type!='30'&&item.list){
                            item.list.forEach(item2=>{
                                var list=[];
                                item2.ActivityCacheDataList.forEach(item3=>{
                                    list.push(item3.ActivityTypeName);
                                });
                                const set = new Set(list);
                                item2.activeList=set;
                            })
                        }
                    });
                    that.isShow = false;
                    that.$nextTick(()=>{
                        that.isShow = true;
                    })
                }
            });
        

        },

        // 楼层列表 type==20的数据
        getFloorList(floorId,id){
            var that = this;
            var data ={
                page:1,
                rows:6,
                id: floorId,
                ItemId:id,
            }
            
            that.public.request('get','/api/indexModule/queryFloorGoodsPageList',data,res => {
                if(res.data.errorCode == '00'){
                    res.floorId = id;
                    for(var i=0; i<that.floorList.length; i++){
                        if(that.floorList[i].tit.Id == id){
                            that.floorList[i].list = res.data.rows;
                        }
                    }
                    that.floorArr = that.floorList;
                    that.floorArr.forEach(item=>{
                        if(item.tit.Type!='30'&&item.list){
                            item.list.forEach(item2=>{
                                var list=[];
                                item2.ActivityCacheDataList.forEach(item3=>{
                                    list.push(item3.ActivityTypeName);
                                });
                                const set = new Set(list);
                                item2.activeList=set;
                            })
                        }
                    })
                    that.isShow = false;
                    that.$nextTick(()=>{
                        that.isShow = true;
                    })
                    // console.log("-------新的楼层type=20------");
                }
            })
        },


        // 猜你喜欢
        guessLike(){
			return;
            var that = this;
            var orgId = localStorage.getItem("orgId");
            var page = that.page;
           that.noMoretip=false;
            
            var data = {
                OrgId: orgId,
                page: page,
                rows: 6,
            }
            if(page>1){
                that.showLoading = true;
            }
            var IsSpecial = sessionStorage.getItem("IsSpecial")
            if(IsSpecial){
                var url = '/api/SCustomerGoods/QueryPage';
            }else{
                var url = '/api/Store/QueryStoreGoods';
            }
            that.public.request('post',url,data,res => {
                // console.log("猜你喜欢",res);
                // console.log('that.page;',that.page);
                
                that.showLoading = false;
                if(page>1){
                    that.showLoading = false;
                }
                if(res.data.errorCode == "00"){
                    var allArr = [];
                    var initArr = that.likeList;
                    var curArr = res.data.rows ? res.data.rows : [];
                    var pageLength = curArr.length;
                    if(page > 1){
                        allArr = initArr.concat(curArr);
                    }else{
                        allArr = res.data.rows;
                    }
                    if(page == 1){
                        that.isLike = false;
                        that.$nextTick(()=>{
                            that.isLike = true;
                        })
                    }
                    that.likeList = allArr;
                    // console.log("----------",that.likeList);
                    if(pageLength < 6){
                        that.noMoretip = true;
                    }
                }else{
                    if(page <=1){
                        that.likeList = [];
                        that.noMoretip = false;
                    }else{
                        that.noMoretip = true;
                    }
                }
                // console.log("----------",that.likeList);
                that.likeList.forEach(item=>{
                    var list=[];
                    if(item.ActivityCacheDataList != null){
                        item.ActivityCacheDataList.forEach(item1=>{
                            list.push(item1.ActivityTypeName);
                        });
                        const set = new Set(list);
                        item.activeList=set;
                    }
                    if(item.ActivityCacheDataList == null){
                        item.ActivityCacheDataList = [];
                    }
                });
            })
        },


        // 公告
        getNotice(){
            var that = this;
            var data = {}
            that.public.request('post','/api/noticeBoard/queryNoticeBoard',data,res => {
                if(res.data.errorCode == '00'){
                    that.noticeList = res.data.rows;
                }
            })
        },
        // 弹窗信息
        getPopupInfo(){
            var that = this;
            var data = {}
            // that.$toast.loading({
            //     message: '加载中...',
            //     duration: 0,
            // })
            that.public.request('post','/api/Store/QueryIndexPopupLayer',data,res => {
                // that.$toast.clear();
                if(res.data.errorCode == '00'){
                    if(res.data.rows == null){
                        return false;
                    }
                    var popupInfo = res.data.rows;
					if(popupInfo.Category != 1){//1 活动图片， 2签到积分
						that.getPoint();
						that.onSignShow();
						return;
					}
                    if(Object.keys(popupInfo).length>0 && popupInfo.Pic){
                        // 如果为null,变为false
                        if(popupInfo.ShowRepetition == null){
                            popupInfo.ShowRepetition = false;
                        }

                        // 获取localStorage的ShowRepetition
                        var ShowRepetition = localStorage.ShowRepetition;
                        if(ShowRepetition == undefined){
                            that.popLayerShow = true;
                            localStorage.setItem("ShowRepetition", popupInfo.ShowRepetition);
                            that.popupInfo = popupInfo;
                            return false;
                        }
                        // 如果ShowRepetition为true,则重新进入显示弹窗
                        // console.log(ShowRepetition);
                        // console.log(popupInfo.ShowRepetition);
                        if(popupInfo.ShowRepetition && ShowRepetition == 'true'){
                            that.popLayerShow = true;
                        }else if(!popupInfo.ShowRepetition && ShowRepetition == 'false'){   
                            that.popLayerShow = false;
                        }else if(popupInfo.ShowRepetition && ShowRepetition == 'false'){   // 从false变为true
                            that.popLayerShow = true;
                        }else if(!popupInfo.ShowRepetition && ShowRepetition == 'true'){  // 从true变为false  只显示一次
                            that.popLayerShow = true;
                        }
                        localStorage.setItem("ShowRepetition", popupInfo.ShowRepetition);
                        that.popupInfo = popupInfo;
                    }
                }else if(res.data.errorCode == '99'){
                    that.$toast.clear();
                    if(res.data.errorMsg == '该用户未注册'){
                        that.$router.push({
                            path: '/login'
                        })
                    }
                }
            })
        },
        // 关闭弹窗
        closePopup(){
            var that = this;
            that.moneyPopup = false;
        },


        // 今日特价
        getDailySpecial(id){
            var that = this;
            var data = {
                page: 1,
                rows: 6,
            }
            that.public.request('get','/api/DailySpecial/QueryList',data,res => {
                if(res.data.errorCode == '00'){
                    // console.log(res);
                    res.data.floorId = id;
                    var list=res.data.rows;
                    list.forEach((item,ind)=>{
                       let num=item.SecKillNum/item.GoodsNum;
                        num = (num*100);
                       list[ind].percentage=num;
                     });
                     that.specialList=list;
                }  
            });
        },

        // 近效期
        getNearTerm(id){
            var that = this;
            var data = {
                page: 1,
                rows: 6,
            }
            that.public.request('get','/api/nearExpiry/queryList',data,res => {
                if(res.data.errorCode == '00'){
                    res.data.floorId = id;
                    // console.log(res);
                    that.newFloorArr(res,id);
                }
                
                
            })
        },
        // 秒杀
        getSeckill(id){
            var that = this;
            var data = {
                page: 1,
                rows: 6,
            }
            that.public.request('post','/api/SecKill/QueryList',data,res => {
                if(res.data.errorCode == '00'){
                    // console.log('秒杀',res);
                    res.data.floorId = id;
                    var goodList = res;
                    goodList.data.rows.forEach((item,i)=>{
                        // 转化为时间戳
                        item.timeStamp = that.public.timeStamp(item.EndTime);
                    })
                    that.newFloorArr(goodList,id);
                }
            })
        },
          // 秒杀商品
        getSeckillList(id){
            var that = this;
            var data = {
                hour:id,
				rows: 5
            }
             that.timeShop=[];
             that.allTimeList=[];
             that.timeList=[];
             that.activeTime=0;
            that.public.request('post','/api/seckill/QueryListNew',data,res => {
                if(res.data.errorCode == '00'){
                //    console.log('执行时间商品',res.data.rows);
                   that.allTimeList=res.data.rows;
                   res.data.rows.forEach(item=>{
                        var h_hour = new Date().getHours();//当前小时
                         var data={
                                hour:item.hour,
                                title:item.title,
                            }
                        if(h_hour==item.hour){
                           that.timeList.push(data);
                            var list=item.list;
                            list.forEach((item,ind)=>{
                              let num=item.SecKillNum/item.GoodsNum;
                               num = (num*100);
                              list[ind].percentage=num;
                            });
                             that.timeShop=list;
                            that.activeTime=1;
                        }else if(h_hour<item.hour){
                            that.timeList.push(data);
                        }
                   });
                    //时间排序
                   that.timeList.sort((a,b) => {
                       return a.hour-b.hour
                       });

                      // 拿到当前时间的数据
                     if( that.timeShop.length=='0'){
                       res.data.rows.forEach(item=>{
                       if(that.timeList[0].hour==item.hour){
                         that.timeShop= item.list;
                       }
                       });
                     }
                }
                // console.log(that.activeTime);
                
            })
        },
        // 团购
        getgroupBuy(id,type){
            var that = this;
            var data = {
                page: 1,
                rows: 6,
                Type: type
            }

            that.public.request('post','/api/GroupActivity/QueryList',data,res => {
                if(res.data.errorCode == '00'){
                    res.data.floorId = id;
                    var goodList = res;
                    goodList.data.rows.forEach((item,i)=>{
                        // 转化为时间戳
                        item.timeStamp = that.public.timeStamp(item.EndTime);
                    })
                    that.newFloorArr(goodList,id);
                }
            })
        },
        // 组成新的楼层数组
        newFloorArr(res,id){
            var that = this;
            // console.log(res);
            var listArr = res.data;
            // console.log('旧的楼层',that.floorList);
            for(var i=0; i<that.floorList.length; i++){
                if(that.floorList[i].tit.Id == listArr.floorId){
                    that.floorList[i].list = listArr.rows;
                }
            }
            // 将活动商品放在上边
            that.floorList.forEach((item,ind)=>{
                if(item.tit.Type=='30'){
                    var aa=item;
                    that.floorList.splice(ind, 1);
                    that.floorList.unshift(aa);
                }
            });
            that.floorArr = that.floorList;
         
            that.floorArr.forEach(item=>{
                if(item.tit.Type!='30'&&item.list){
                    item.list.forEach(item2=>{
                        var list=[];
                        item2.ActivityCacheDataList.forEach(item3=>{
                              list.push(item3.ActivityTypeName);
                        });
                        const set = new Set(list);
                        item2.activeList=set;
                    })
                }
            });
            //    console.log("-------新的楼层floorArr-、-----",that.floorArr);
            that.isShow = false;
            that.$nextTick(()=>{
                that.isShow = true;
            });
            
        },

        // 跳转公告详情
        toNoticeDetail(item){
            var that = this;
            if(item.URL.indexOf('http') != '-1'){
                 window.location.href = item.URL;
            }else{
                 let id='';
                if(item.URL){
                    let arr=item.URL.split('/');
                    id=arr[3];
                }
                that.$router.push({
					path: '/ArticleDetail',
					query: {Id:id},
				});
            }
        },
        // 导航跳转页面
        navSkip(item){
            var that = this;
            var url = item.Url;
			if(!url){
				return
			}
			// url = "/dailySpecial?name=今日特价"
			if(url.indexOf('?') == -1){
				url = url + "?name=" + item.Name
			}
			var urlObj = that.public.urlObj(url);
			var urlPage = url.split("?")[0];
			var query = {};
			for(var key in urlObj){
			    query[key] = urlObj[key];
			}
			// return
			that.$router.push({
			    path: urlPage,
			    query: query,
			})

        },

        // 楼层 跳转列表页面
        toPage(url){
            var that = this;
            // 判断路由是否携带参数  不带参数
             if(url.indexOf('?') != -1){
				 var urlObj = that.public.urlObj(url);
				 var urlPage = url.split("?")[0];
				 var query = {};
				 for(var key in urlObj){
				     query[key] = urlObj[key];
				 }
				 that.$router.push({
				     path: urlPage,
				     query: query,
				 })
            }else{
                 that.$router.push({
                     path: url,
                 });
            }
        },
		
		/**
		 * 海报宣传文案
		 */
		toPosterPage(){
			this.$router.push({
			    path: "/posterPage",
			});
		},

        // 楼层活动列表详情（团购、秒杀、今日特价、集采）
        toActivityDetail(Url,spid,goodId){
            var that = this;
            if(Url=='/seckillList?headTit=group'){//团购商品
                that.$router.push({
                    path: '/activeDetail',
                    query:{
                        type: 'group',
                        spid: spid,
                        id: goodId,
                    }
                })

            }else if(Url=='/bargainPrice?headTit=bargain'){   // 今日特价
                that.$router.push({
                    path: '/activeDetail',
                    query:{
                        spid: spid,
                        id: goodId,
                        type:'bargain',
                    }
                })
            }else if(Url=='/seckillList?headTit=seckill'){//秒杀
                that.$router.push({
                    path: '/activeDetail',
                    query:{
                        type: 'seckill',
                        spid: spid,
                        id: goodId,
                    }
                })
            }else if(Url=='/seckillList?headTit=purch'){//集采
                  that.$router.push({
                    path: '/activeDetail',
                    query:{
                        type: 'purch',
                        spid: spid,
                        id: goodId,
                    }
                })
            }else if(Url=='/termValidity?headTit=nearExpiry'){//近效期
                 that.$router.push({
                    path: '/activeDetail',
                    query:{
                        spid: spid,
                        id: goodId,
                        type: 'nearExpiry',
                    }
                })
            }
        },

        // 楼层列表跳转详情(猜你喜欢和类型Type20/10的商品列表)
        toDetail(goodInfo,id){
            var that=this;
            //  类型Type20列表 10标签的列表      
            // 跳转普通商品详情
            if(goodInfo.CurActivityData == null&&id==undefined){
                that.$router.push({
                    path: '/goodsDetail',
                    query:{
                        spid: goodInfo.spid,
                        headTit: '详情'
                    }
                })
            }else if(id=='0'){
                that.$router.push({
                    path: '/goodsDetail',
                    query:{
                        spid: goodInfo.GoodsId,
                        headTit: '详情'
                    }
                })
            }else{  // 跳转活动商品详情
                jumpActivity.jumpPage(goodInfo,id);
            }
                  
        },

        
        // 优惠券页面
        toCouponList(){
            this.$router.push({
                path: '/coupon'
            })
        },
        // 套餐包
        toPackage(){
            this.$router.push({
                path: '/Package',
            })
        },
        
        // 近效期页面
        toTermValidity(){
            this.$router.push({
                path: '/termValidity'
            })
        },
        // 品牌专区页面
        toBrandZone(){
            this.$router.push({
                path: '/brandZone'
            })
        },
        // 专区列表
        toBrandList(){
            this.$router.push({
                path: '/brandList'
            })
        },
        // 厂家专区
        toMillZone(){
            this.$router.push({
                path: '/millZone'
            })
        },
        // 厂家列表
        toMillList(){
            this.$router.push({
                path: '/millList'
            })
        },
        // 搜索历史页面
        toSearchHistory(){
            this.$store.commit("sethistoryShow",true);
            // this.$router.push({
            //     path: '/allProducts',
            //     query: {
            //         search: 'search'
            //     }
            // })
            this.$router.push({
                path: '/searchHistory',
            })
        },
		setOneFloorItem(resFloor){
			let that=this;
			that.isOneFloor=true;
			that.oneFloorItem.Page=1;
			that.oneFloorItem.ShowLoading=false;
			that.oneFloorItem.NoMore=false;
		    that.oneFloorItem.Args=resFloor.Args;
			that.oneFloorItem.FloorId=resFloor.FloorId;
			that.oneFloorItem.Id=resFloor.Id;	
		    that.oneFloorItem.Name=resFloor.Name;			
			that.oneFloorItem.Type=resFloor.Type;
				
		},
		loadOneFloorMore(){	
			let that=this;
			that.oneFloorItem.ShowLoading=false;
			if(!that.isOneFloor || !that.floorArr){				
				return;
			}
			
			if(!that.oneFloorItem || !that.oneFloorItem.Id || that.oneFloorItem.NoMore){				
				return;	
			}					
			let floorArrIndex=-1;
			for(var i=0; i<that.floorArr.length; i++){
				if(that.floorArr[i].tit.Id==that.oneFloorItem.Id){
				  floorArrIndex=i;
				}
			}
			if(floorArrIndex==-1)
			return;		
				
			if(!that.floorArr[floorArrIndex].list || that.floorArr[floorArrIndex].list.length<6){
				that.oneFloorItem.NoMore=true;
				return;
			}			
			//10-标签 20-列表 30-url
			if(that.oneFloorItem.Type==10){
				that.oneFloorItem.Page++;
				  that.oneFloorItem.ShowLoading=true;
				  that.sendRequest(that.oneFloorItem.Id,that.oneFloorItem.Args,that.oneFloorItem.Id,that.oneFloorItem.Page).then(res=>{
					  that.oneFloorItem.ShowLoading=false;					
					  if(res.errorCode!="00"){
						 return;
					   }						
					   let resRows=res.rows;
					   if(resRows==null || resRows.length<=0){		
						   that.oneFloorItem.NoMore=true;
						   return;
					   }					  			
					   if(resRows.length<6){
							that.oneFloorItem.NoMore=true;
						}					 			
					  resRows.forEach(item2=>{
						  var list=[];
						  item2.ActivityCacheDataList.forEach(item3=>{
							  list.push(item3.ActivityTypeName);
						  });
						  const set = new Set(list);
						  item2.activeList=set;
					  });					
					 var allArr = that.floorArr[floorArrIndex].list.concat(resRows);	
					 that.$set(that.floorArr[floorArrIndex],"list",allArr);
					 let tempFloor=that.floorArr;
					 tempFloor.forEach((item,index)=>{
						 that.$set(that.floorArr,index,item);
					 });
				}).catch(()=>{
					 that.oneFloorItem.ShowLoading=false;
				})
			}
			//列表
			else if(that.oneFloorItem.Type==20){
				if(!that.oneFloorItem.FloorId)
					return;
				that.oneFloorItem.Page++;
				that.oneFloorItem.ShowLoading=true;	
				let flGoodsData ={
				        page:that.oneFloorItem.Page,
				        rows:6,
				        id: that.oneFloorItem.FloorId,
				        ItemId:that.oneFloorItem.Id,
				    }				    
				    that.public.request('get','/api/indexModule/queryFloorGoodsPageList',flGoodsData,reres => {
						let res=reres.data;					
						that.oneFloorItem.ShowLoading=false;
						if(res.errorCode!="00"){
							return;
						}	
						let resRows=res.rows;
						if(resRows==null || resRows.length<=0){		
							that.oneFloorItem.NoMore=true;
							return;
						}					  			
						if(resRows.length<6){
							that.oneFloorItem.NoMore=true;
						}					 			
						 resRows.forEach(item2=>{
							  var list=[];
							  item2.ActivityCacheDataList.forEach(item3=>{
								  list.push(item3.ActivityTypeName);
							  });
							  const set = new Set(list);
							  item2.activeList=set;
						 });					
						var allArr = that.floorArr[floorArrIndex].list.concat(resRows);	
						that.$set(that.floorArr[floorArrIndex],"list",allArr);
						let tempFloor=that.floorArr;
						tempFloor.forEach((item,index)=>{
							that.$set(that.floorArr,index,item);
						});					
				    })				
			}
		},
        // 到达底部
        onReachBottom(){
            var that = this;
            //变量scrollTop是滚动条滚动时，距离顶部的距离
            var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
            //变量windowHeight是可视区的高度
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
            
            
            if(scrollTop > 0){
                if(scrollTop + windowHeight + 5 > scrollHeight){
					that.loadOneFloorMore();
					return;
                    var likeList = that.likeList;
                    if(likeList.length > 0){
                        var page = that.page;
                        var noMoretip = that.noMoretip;
                        page++;
                        that.page = page;
                        if(noMoretip){
                            // console.log("已经到底了");
                        }else{
                            // console.log("加载更多");
                            that.guessLike();							
                        }
                    }                    
                }
            }
        },
        // 监听返回
        popstateBack(){
            var that = this;
            var isBack = that.isBack;
            if(!isBack){
                var userAgent = navigator.userAgent;
                var isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1; //android终端
                var isIos = (userAgent.indexOf('iphone') > -1 ||
                    userAgent.indexOf('ipad') > -1 ||
                    userAgent.indexOf('ipod') > -1) &&
                (platform.indexOf('iphone') > -1 ||
                    platform.indexOf('ipad') > -1 ||
                    platform.indexOf('ipod') > -1);
                
                if (window.WeixinJSBridge) {
                    window.WeixinJSBridge.call('closeWindow') // 微信
                } else if (window.AlipayJSBridge) {
                    window.AlipayJSBridge.call('closeWebview') // 支付宝
                }else if (isAndroid) {
                    window.obj.closePageLppzRequest('') // 安卓app
                }else if (isIos) {
                    window.webkit.messageHandlers.closePageLppzRequest.postMessage('') //ios app
                }else {
                    location.replace("about:blank", "_top");
                }
            }
        },
        createXmlHttpRequest(){
            var xmlHttp;
            if(window.ActiveXobject){ // 判断是否是ie浏览器
                try { // try开始
                    xmlhttp = new ActiveXobject("Microsoft.XMLHTTP"); // 使用ActiveX对象创建ajax
                }catch(e){
                    xmlHttp = false;
                } // try end
            }else{   //Chrome、FireFox等非ie内核
                try{
					xmlHttp = new XMLHttpRequest(); //视为非ie情况下
                }catch(e){
                    xmlHttp = false; // 其他非主流浏览器
                }
            } // 判断结束,如果创建成功则返回一个DOM对象,如果创建不成功则返回一个false
            if(xmlHttp){
                return xmlHttp;
            }else{
                alert("xmlhttp对象创建失败,请检查浏览器是否支持XmlHttpRequest!");
            }
        },
        /**
         * 小程序登录
         */
         AppletLogin(jumid){
            var that = this;
			// jumid = "9648a7b38bf1ca7017a728f37383bb83";
            localStorage.setItem("clientType", "applets"); // app类型
            var xmlobj = that.createXmlHttpRequest();
            xmlobj.open("post",window.baseUrl.url+"api/AppLogin/GetAppletLogin",false);
            xmlobj.setRequestHeader("cache-control","no-cache");
            xmlobj.setRequestHeader("Content-Type", "application/json;charset=utf-8"); //设置请求头信息
            xmlobj.onreadystatechange = function() {
                if(xmlobj.readyState == 4 && xmlobj.status == 200){
                    try{
                        var res = JSON.parse(xmlobj.responseText);
						 // alert("小程序登录信息: "+JSON.stringify(res))
                        if(res.errorCode=='00'){
                            that.$toast.clear();
							sessionStorage.setItem("member_token", res.rows);
							localStorage.setItem("orgId", res.errorMsg); 
							if(res.extend){
								if(res.extend.customerId){
									sessionStorage.setItem("customer", res.extend.customerId);
									if(res.extend.customerId == "BC0757BD9B4B4334B7149C1AA59E561F"){
										sessionStorage.setItem("member_token", "");
									}
								}
								if(res.extend.IsSpecial){// 测试账号，审核展示数据
									sessionStorage.setItem("IsSpecial",res.extend.IsSpecial); 
								}
								if(res.extend.IsPush){
									localStorage.setItem("isPush",res.extend.IsPush); //个性化推荐
								}
								if(res.extend.webConfig){
									localStorage.setItem("webconfig", JSON.stringify(res.extend.webConfig));
								}
								if(res.extend.Applets){
									localStorage.setItem("openid",res.extend.Applets);
								}
							}
                        }else{
							sessionStorage.setItem("member_token", ""); //未注册过的所有游客
                            that.$toast(res.errorMsg);
                        }
						// that.isLogin();
                    }catch(e){
						 // that.$router.push({
							//  url:"/login"
						 // });     
                    }
                   
                }
            };
            xmlobj.send(JSON.stringify({Mobile:jumid})); //设置为发送给服务器数据
        },

        /**
         *  判断当前是否为小程序或者公众号
         *  获取小程序或者公众号的oppenid
         *  判断localstorage是否有token
         *  有token，通过oppennid请求账号，密码、token,默认登录
         */
		
		//新品推荐
		handleRecommend(url){
			this.$router.push({
				path: url?url:"/productRecommend",
				query: {
				}
			})
		},
		
		// 签到弹窗显示
		onSignShow(){
		  var that = this;
		  var data = {}
		  that.public.request("post",'/api/SignIn/QuerySignInInfo',data, res => {
		    // console.log(res);
		    if(res.data.errorCode == '00'){
		      that.signData = res.data.rows;
			  if(that.signData.IsSignIn){//已签过到
			  	return;
			  }
		      that.signShow = true;
		    }else if(res.data.errorCode == '99'){
		      // that.$toast(res.data.errorMsg);
		    }
		  })
		},
		// 去签到
		onSignTap(){
		  var that = this;
		  var data = {};
		  that.public.request('post','/api/SignIn/SignIn',data, res => {
		    if(res.data.errorCode == '00'){
		     that.onSignShow();
		     that.getPoint();
		    }else if(res.data.errorCode == '99'){
		      that.$toast(res.data.errorMsg);
		    }
		  })
		},
		// 关闭弹窗
		closeSign(){
		  var that = this;
		  that.signShow = false; 
		  
		},
		// 获取积分
		getPoint(){
		  var that = this;
		  var data = {}
		  
		  that.public.request('post','/api/PointsMain/PersonInfoPoints',data, res => {
		    // console.log(res);
		    if(res.data.errorCode != '00'){
		      return false;
		    }
		    that.pointsSetting = res.data.rows;
		    that.pointExtend = res.data.extend;
		  })
		},
        
    },
    
    filters:{
        // 局部过滤器
        timeFormat(timeDate){
            
            // 天时分秒  倒计时
            var time = timeDate;
            var day = parseInt(time / 60 / 60 / 24);
            var hourse = parseInt(time / 60 / 60) % 24;
            var minute = parseInt(time / 60) % 60;
            var second = parseInt(time % 60);
            hourse = hourse < 10 ? (`0${hourse}`) : hourse;
            minute = minute < 10 ? (`0${minute}`) : minute;
            second = second < 10 ? (`0${second}`) : second;
            var timeDown = day + "天" + hourse + ":" + minute + ":" + second;
            return timeDown;
            
        }
    },
    
}
</script>


<style scoped>
    .contain{
        width:100%;
    }
    /* 搜索框 */
    .input-box{
        width:100%;
        padding:0.16rem 0;
		/* background: #00a2ea; */
		background: var(--theme-color);
    }
    .input-box .scan-code{
        width:0.46rem;
        height:0.46rem;
        margin-right:0.2rem;
    }
    .input-box .search-input{
        flex:1;
        border:0.02rem solid #eee;
        border-radius:0.15rem;
        padding: 0.14rem 0.2rem;
        box-sizing:border-box;
		background: #fff;
		margin: 0 0.2rem;
		border-radius: 50px;
    }
    .input-box .search-input input{
        flex:1;
        font-size:0.28rem;
    }
    .input-box .search-input img{
        width:0.36rem;
        height:0.36rem;
        margin-left:0.2rem;
    }
	
	::-webkit-scrollbar { width: 0px;height: 0px;}
	.input-box .scroll-h{
		position: relative;
		overflow: hidden;
		overflow-x: scroll;
	}
	.input-box .scroll-h .scroll-wrapper{
		padding: 8px 6px 2px;
		display: flex;
		white-space: nowrap;
	}
	.input-box .scroll-h .scroll-wrapper .scroll-wrapper-i{
		display: inline-block;
		margin: 0 5px;
		padding: 1px 12px;
		background: #6FB1FD;
		color: #fff;
		font-size: 13px;
		border-radius: 50px;
	}

    /* 图片轮播 */
    .swiper-box{
        width:100%;
        /* height:3rem; */
    }
    
    .swiper-box .my-swipe{
        width:100%;
        height:100%;
    }
    .swiper-box .my-swipe .item-img{
        width:100%;
        height:100%;
    }
    .swiper-box .my-swipe .item-img img{
        width:100%;
        height:100%;
    }

    /* 导航 */
    .nav-list{
        width:100%;
        padding:0.1rem 0;
        box-sizing:border-box;
        flex-wrap:wrap;
    }
    .nav-list .nav-item{
        width:20%;
        padding:0.2rem 0;
        box-sizing:border-box;
    }
    .nav-list .nav-item img{
        width:0.9rem;
        height:0.9rem;
        margin-bottom:0.2rem;
        border-radius:50%;
    }
    .nav-list .nav-item .item-text{
        font-size:0.26rem;
        color:#333;
        display:block;
        text-align:center;
    }

    /* 公告 */
    .notice-box{
        width:100%;
        box-sizing:border-box;
    }
    .notice-box .notice-list{
        flex:1;
        height:100%;
    }
    .notice-swipe{
        height:40px;
        line-height:40px;
        color:#333;
    }

    /* 限时秒杀 */
    .seckill-box{ width:100%; }
    .seckill-box .seck-tit{ width:100%; height:0.8rem; padding:0 0.3rem; box-sizing:border-box; border-bottom:0.02rem solid #eee;  }
    .seckill-box .seck-tit .title{ font-size:0.3rem; color:#333; font-weight:600; }
    .seckill-box .seck-tit .seck-more span{ font-size:0.28rem; color:#333; }
    .seckill-box .seck-tit .seck-more img{ width:0.39rem; height:0.36rem; margin-left:0.2rem; }

    .seckill-box .seck-cont{ width:100%;padding:0.2rem; box-sizing:border-box;position:relative; }
    .seckill-box .seck-cont .right-arrow{ position:absolute;bottom:2.6rem;right:0.3rem;z-index:1;width:0.8rem;height:0.8rem;background:#fff;border-radius:50%; }
    .seckill-box .seck-list { width:100%;  box-sizing:border-box; overflow-x:auto; height:4.8rem; position:relative; flex-wrap:nowrap; }
    /* .seck-absolute{ position:absolute; left:0; top:0; width:100%;  } */
    .seck-list .seck-item {    margin: 0 0.1rem; width:3.2rem;height: 4.15rem; border:0.02rem solid #eee;box-shadow: 0px 0px 5px #b9b9b9; margin-right:0.2rem; }
    .seck-list .seck-item .item-img{ width:3.2rem; height:2.5rem; position:relative;display:flex;align-items:center; }
    .seck-item .item-img  .shop-img{ width:90%; margin:0 auto; object-fit: contain;}
    .seck-item .item-img .item-setime{ position:absolute; left:2%; bottom:-1.56rem; right:0; padding:0.01rem 0.2rem; background:#199ed8; border-radius:0.28rem; width:65%; }
    .seck-item .item-img .item-setime img{ width:0.22rem; height:0.22rem; margin-right:0.1rem; }
    .seck-item .item-img .item-setime .settime-tit{ color:#fff; font-size:0.24rem;}
    .seck-item .item-info { width:100%; padding:0.4rem 0.12rem 0.1rem 0.12rem; box-sizing:border-box; position:relative; }
    .seck-item .item-info span{ display:block; font-size:0.26rem; }
    .seck-item .item-info span.info-name{ width:100%; overflow:hidden; white-space:nowrap; text-overflow:ellipsis;font-weight:600; }
    .seck-item .item-info span.info-specifi{ font-size:0.24rem; overflow:hidden; white-space:nowrap; text-overflow:ellipsis;}
    .seck-item .item-info span.info-company{ font-size:0.24rem; width:100%; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; }
    .seck-item .item-info span.info-price{ font-size:0.32rem; }
    .seck-item .item-info span.info-old-price{ font-size:0.22rem; text-decoration:line-through; }
    .seck-item .item-info .shop-cart{ position:absolute; right:0.2rem;  width:0.36rem; }

    /* 品牌专区 */
    .seck-cont .brand-list{ width:100%;  box-sizing:border-box;flex-wrap:wrap; }
    .seck-cont .brand-list .brand-item{ width:3.4rem; border:0.02rem solid #eee; box-shadow:0 0 0.1rem #eee; margin-right:0.2rem;margin-bottom:0.2rem; }
    .seck-cont .brand-list .brand-item:nth-child(2n){ margin-right:0; }
    .seck-cont .brand-list .brand-item .item-img{ width:100%;height:2.5rem; }
    .seck-cont .brand-list .brand-item .item-img img{ width:100%; max-height:2.5rem; }
    .brand-list .brand-item .brand-good{ width:100%;padding:0.2rem;box-sizing:border-box;  }
    .brand-list .brand-item .brand-good .brand-img{ width:50%;height:1.2rem; }
    .brand-list .brand-item .brand-good .brand-img img{ width:100%;max-height:100%; }

    /* 楼层 */
    .floor-box{ width:100%; }
	.floor-box .floor-floorpic{border-bottom:0.1rem solid #f7f7f7}
	.floor-box .floor-title{height:0.8rem; padding: 0 0.3rem; font-size:0.3rem;line-height:0.8rem;font-weight:600;}
    .floor-box .floor-title .title{ font-size:0.3rem; color:#333; font-weight:600; }
    .floor-box .floor-title .seck-more span{ font-size:0.28rem; color:#333;font-weight:100; }
    .floor-box .floor-title .seck-more img{ width:0.4rem; height:0.5rem; margin-left:0.2rem; }

    .floor-box .floor-img{ width:100%;padding:0.2rem 0.3rem;box-sizing:border-box;border-bottom:0.02rem solid #eee; }
    .floor-box .floor-img img{ width:100%; }

    .floor-box .floor-list{ width:100%; background: #f7f7f7;}
    .floor-list .floor-item{ width:100%;padding:0.2rem 0.3rem;box-sizing:border-box; border-bottom:0.02rem solid #eee;}
    .floor-list .floor-item:last-child{border-bottom:none;}
    .floor-list .floor-item .floor-good{ width:2.2rem;max-height:2.5rem;margin-right:0.2rem;min-width:2.2rem;object-fit: contain; }
    .floor-list .floor-item .floor-specifi{ position:relative; width:100%;}
    .floor-list .floor-item .floor-specifi span{ display:block; }
    .floor-list .floor-item .floor-specifi span.specifi-name{ font-size:0.3rem;width:4rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;font-weight:600; }
    .floor-list .floor-item .floor-specifi span.specifi-num{ font-size:0.24rem; }
    .specifi-activity{ font-size:0.24rem;width:100%;padding-right:0.4rem;box-sizing:border-box; }
    .specifi-activity .active-span{ display:inline !important;margin-right:0.2rem; font-size: .28rem /* 16/50 */; font-weight: 700;}
    .floor-list .floor-item .floor-specifi span.specifi-company{ font-size:0.28rem;width:4rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis; }
    .floor-list .floor-item .floor-specifi span.specifi-price{ font-size:0.32rem; }
    .floor-list .floor-item .floor-specifi span.specifi-old-price{ font-size:0.22rem;text-decoration:line-through; }
    .floor-list .floor-item .floor-specifi .shop-cart{ position:absolute;right:0rem;bottom:0.3rem;width:0.36rem; }

    /* 红包弹窗 */
    .popup-money{ position:fixed;top:0;left:0;right:0;bottom:0;background:rgba(0,0,0,0.6);z-index:1; }
    .popup-cont-layer{ position:fixed;top:20%;left:10%;z-index:2;width:80%; }
    .popup-cont-layer .popup-img{ width:100%; max-height:7rem;margin:0rem auto 0.8rem; }
    .popup-cont-layer .popup-close{ width:0.8rem;height:0.8rem;margin:0 auto;}

    /* 商品弹窗 */
    .good-popup{ position:fixed;top:0;left:0;right:0;bottom:0;background:rgba(0,0,0,0.5);z-index:1 }
    .good-pop{ position:fixed;left:0;bottom: 1.12rem !important;;z-index:2;background:#fff;width:100%; }
    .good-pop .good-top{ width:100%;padding:0.18rem 0.2rem;box-sizing:border-box;border-bottom:0.02rem solid #eee; }
    .good-pop .good-top img{ width:1.6rem;max-height:1.6rem;margin-right:0.2rem; }
    .good-pop .good-top .good-right{ flex:1;justify-content:space-between; }
    .good-pop .good-top .good-right .right-name{ font-size:0.3rem;font-weight:600; }
    .good-pop .good-top .good-right .right-price span.price-tit{ font-size:0.5rem; }
    .good-pop .good-top .good-right .right-price span{ font-size:0.28rem; }

    .good-pop .good-specifi{ width:100%;padding:0.18rem 0.2rem;box-sizing:border-box; }
    .good-pop .good-specifi .specifi-item{ margin-bottom:0.12rem; }
    .good-pop .good-specifi .specifi-item .item-title .tit{ font-size:0.28rem;color:#333;font-weight:600;display:block; }
    .good-pop .good-specifi .specifi-item .item-title .tit-parameter{ font-size:0.28rem;color:#999; }
    .good-pop .good-specifi .specifi-item .last-span{ margin-left:0.28rem;color:#999;font-size:0.26rem; }
    /* 商品加减 */
    .add-subtract{ flex:1;justify-content:flex-end }
    .add-subtract .add{ font-size:0.36rem;border:0.05rem solid #ff1515;color:#ff1515;border-radius:50%;display:inline-block;width:0.38rem;height:0.38rem;text-align:center;line-height:0.3rem;box-sizing:border-box; }
    .add-subtract .num{ font-size:0.3rem;margin:0 0.2rem;width: 1rem;text-align: center;border: 0.02rem solid #999;border-radius:0.12rem;line-height:inherit; }
    .add-subtract .cut{ font-size:0.36rem;background:#ff1515;color:#fff;border-radius:50%;display:inline-block;width:0.38rem;height:0.38rem;text-align:center;line-height:0.3rem; }

    .good-pop .good-sure{ width:100%;height:1rem;text-align:center;line-height:0.8rem;color:#fff;background:#199ed8;margin:0.2rem auto 0; font-size:0.3rem;}

    /* 购物车弹窗活动 */
    .good-pop .good-specifi .specifi-item .active-item{ width:100%;font-size:0.3rem;color:#ff1515;padding:0.06rem 0;box-sizing:border-box; }
    .good-pop .good-specifi .specifi-item .active-item label{ margin-left:0.2rem; }


    .good-code{ font-size:0.28rem; }

    /* 编辑商品数量弹窗 */
        .popup-box{
            position:fixed;
            left:0;
            top:0;
            right:0;
            bottom:0;
            background:rgba(0,0,0,0.4);
            z-index:11000;
        }
        .popup-cont{
            position:fixed;
            width:70%;
            padding:0.2rem 0 0;
            box-sizing:border-box;
            background:#fff;
            border-radius:0.12rem;
            left:15%;
            top:25%;
            z-index:11001;
        }
        .popup-cont .good-tit{
            width:100%;
            text-align:center;
            font-size:0.3rem;
            margin-bottom:0.2rem;
        }
        .popup-cont .good-num-box{
            width:100%;
            display:flex;
            align-items:center;
            justify-content:center;
            margin-bottom:0.1rem;
            padding: 0 0.2rem;
            box-sizing:border-box;
        }
        .popup-cont .good-num-box .reduce-g{
            font-size:0.39rem;
        }
        .popup-cont .good-num-box input{
            width:2rem;
            height:0.6rem;
            padding:0.1rem;
            margin:0 0.12rem;
            text-align:center;
            border: 0.02rem solid #c9c4c4;
            border-radius:0.12rem;
            font-size:0.3rem;
        }
        .popup-cont .good-num-box .add-g{
            font-size:0.39rem;
        }
        .popup-cont .good-btn{
            width:100%;
            height:50px;
            display:flex;
            align-items:center;
        }
        
        .popup-cont .good-btn .btn-g{
            width:50%;
            font-size:0.3rem;
            text-align:center;
            color:#999;
        }
        .popup-cont .good-btn .btn-g.blue{
            color:#58A0F2;
        }
        .noShopImg{position:absolute;top: 0.4rem;right: 0.8rem; width: 1.6rem; height: 1.6rem;color: white; background-color: rgba(128, 127, 127, 0.8);border-radius: 50%;text-align: center;line-height: 1.6rem;font-size: .5rem; }
        .bjt{position: absolute;top: 0;}
        .guige{position: absolute;top: 0;color: white;font-size: .25rem;font-weight: 700;    width: 53%;text-align: center; height: 0.4rem; overflow: hidden; white-space: nowrap;text-overflow: ellipsis;}
        .jiage{position: absolute; top: -24px; color: white; right: 0.01rem; width: 35%;font-weight: 700;text-align: center;white-space: nowrap; overflow: hidden;}
        .yuanjia{position: absolute;top:0rem;right: 0.01rem;  color: white;  width: 35%; text-align: center;font-size: .25rem; font-weight: 700;white-space: nowrap; overflow: hidden;}
        .carts{bottom: .2rem;}

/* 专区 */
.zone{flex-wrap: wrap;box-sizing: border-box; padding: 5px 0.2rem;}
.zoneItem{ width: 48.5%;  height: 1.79rem; border-radius: 0.2rem; margin: 0.05rem;overflow: hidden;}
.zoneItem img{width: 100%;height: 100%; object-fit: fill;border-radius: 0.2rem;}
.lastNum{position: absolute;}
/* 秒杀专区 */
.Seckill{ box-sizing: border-box;   border-bottom:0.18rem solid #f7f7f7;}
.Seckill .title{ line-height: 0.8rem; padding: 0 0.3rem; font-size: 0.3rem; color: #333;font-weight: 600;}
.flex{display: flex;align-items: center;}
.Seckill-top{margin: 0.13rem 0.22rem;}
.Seckill-top img{width: 0.8rem; border-radius: 50%;}
.Seckill-heading{width: 1rem; text-align: center;margin-left: 0.25rem;}
.font-w{font-weight: 600;font-size: 0.32rem;}
.font-hui{color: rgb(97, 97, 97);}
.Seckill-time{margin-left: 0.15rem; flex: 1;display: flex;box-sizing: border-box;overflow-x: scroll;flex-wrap: nowrap;}
.Seckill-List{text-align: center;margin-right: 0.23rem;}
/* .Seckill-List:last-child{margin-right: 0} */
.time-item div:nth-child(2){width: 1rem;}
.clickTime div:first-child{color: red;}
.clickTime div:nth-child(2){background-color: red;border-radius: 0.2rem;color: white;}
.Seckill-time::-webkit-scrollbar {display:none ;}
/* 今日特价 */
.tejia-box{ box-sizing: border-box;   border-bottom:0.18rem solid #f7f7f7;}
.tejia{ padding:0 0.3rem;}
.tejia .title{ line-height: 0.8rem; font-size: 0.3rem; color: #333;font-weight: 600;}
.tejia .seck-more span{ font-size:0.28rem; color:#333; }
.tejia .seck-more img{ width:0.39rem; height:0.36rem; margin-left:0.2rem; }
/* 新品推荐 */
.new-swipe-box{
	position: relative;
	background: #f7f7f7;
	padding: 0 10px 10px 10px;
}
.new-swipe{height: 90px;}
.new-swipe .new-swipe-item{position: relative;}
.new-swipe .new-swipe-item img{
	width: 100%;
	height: 90px;
	/* border-radius: 30px; */
}

/* 签到 */
.sign-pop-box{
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background:rgba(0,0,0,0.5);
  z-index:10;
}
.sign-pop{
  position:fixed;
  width:90%;
  left:5%;
  top:20%;
  z-index:11;
}
.sign-pop .sign-top{
  width:100%;
  height:0.8rem;
  background:var(--theme-color);
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  padding:0 0.3rem;
  box-sizing:border-box;
}
.sign-pop .sign-top .top-tit{
  flex:1;
  height:100%;
  line-height:0.8rem;
  color:#fff;
  text-align:center;
  font-size:0.36rem;
}
.sign-pop .sign-top .top-close{
  height:100%;
  font-size:0.5rem;
  color:#fff;
  
}
.sign-pop .sign-box{
  width:100%;
  background:#fff;
  padding:0.3rem 0 0.2rem;
  box-sizing:border-box;
  border-radius:0 0 0.2rem 0.2rem;
}
.sign-pop .sign-box .sign-tit{
  width:100%;
  font-size:0.26rem;
  text-align:center;
  margin-bottom:0.3rem;
}
.sign-pop .sign-box .sign-day{
  width:100%;
  padding:0 0.1rem;
  box-sizing:border-box;
  margin-bottom:0.3rem;
}
.sign-pop .sign-box .sign-day .day-item{ width:0.85rem;}
.sign-pop .sign-box .sign-day .day-item .item-box{ align-items:center; background:#fafafa;  border-radius:0.12rem;}
.sign-pop .sign-box .sign-day .day-item .item-box.active{ background:var(--theme-color)}
.sign-pop .sign-box .sign-day .day-item .item-box.active .item-num{ color:#fff;}
.sign-pop .sign-box .sign-day .day-item .item-box .item-num{ font-size:0.27rem;}
.sign-pop .sign-box .sign-day .day-item .item-box .item-img{ width:0.68rem; margin-bottom:0.2rem;}
.sign-pop .sign-box .sign-day .day-item .item-d{ display:block; text-align:center;  font-size:0.24rem;}
.sign-pop .sign-box .sign-t{ font-size:0.32rem;  text-align:center;}
.sign-pop .sign-box .sign-point{  margin:0.2rem 0;}
.sign-pop .sign-box .sign-point .pImg{ width:0.63rem;}
.sign-pop .sign-box .sign-point .gImg{ width: 1rem; max-height: 40px;}
.sign-pop .sign-box .sign-point span{font-size:0.3rem;}
.sign-pop .sign-box .sign-btn{ width:70%; height:0.6rem; line-height:0.6rem; text-align:center;font-size:0.3rem; color:#fff; border-radius:0.2rem; margin:0.3rem auto;}
.sign-pop .sign-box .sign-btn.sign-blue{  background:var(--theme-color)}
.sign-pop .sign-box .sign-btn.sign-grey{ background:#C3C4C4;}
.sign-blue{ color:var(--theme-color);}

.Seckill-more{
	display: flex;
	align-items: center;
	color: #666;
	font-size: 14px;
}
.Seckill-more .arrow{
	width: 19px;
	height: 17px;
	margin-left: 10px;
}

/* 缺货登记弹窗 */
	.register-popup {
	  position: fixed;
	  width: 100%;
	  height: 100%;
	  top: 0;
	  left: 0;
	  background: rgba(0, 0, 0, 0.6);
	  z-index: 99;
	}
	.register-cont {
	  position: fixed;
	  width: 86%;
	  top: 4.5rem;
	  left: 7%;
	  background: #fff;
	  z-index: 7;
	  padding: 0.2rem;
	  box-sizing: border-box;
	  border-radius: 0.2rem;
	  z-index: 999;
	}
	.register-cont .regis-tit {
	  width: 100%;
	  text-align: center;
	  font-size: 0.39rem;
	}
	.register-cont .regis-item {
	  margin-bottom: 0.2rem;
	  border-bottom: 0.02rem solid #eee;
	  height: 0.8rem;
	}
	.register-cont .regis-item .item-tit {
	  width: 2.3rem;
	  font-size: 0.3rem;
	}
	.register-cont .regis-item .input-s {
	  flex: 1;
	}
	.register-cont .regis-item .input-s input {
	  font-size: 0.3rem;
	  flex: 1;
	}
	.register-cont .regis-item .input-s img {
	  width: 0.16rem;
	  margin-left: 0.2rem;
	}
	.register-cont .regis-item .input-s .input-tit {
	  font-size: 0.32rem;
	}
	
	.register-cont .regis-but {
	  width: 100%;
	  justify-content: space-around;
	}
	.register-cont .regis-but .but-around {
	  width: 1.8rem;
	  height: 0.6rem;
	  font-size: 0.3rem;
	  text-align: center;
	  line-height: 0.6rem;
	  border-radius: 0.2rem;
	  color: #fff;
	}
	.register-cont .regis-but .but-around.grey {
	  background: #c0bfbf;
	}
	.register-cont .regis-but .but-around.blue {
	  background: #0cb4e7;
	}
	
	.poster-cell{
		padding: 2px 15px;
		margin: 5px;
		border: 1px #eee solid;
		border-radius: 30px;
		background: #f7f7f7;
	}
	.poster-item{
		position: relative;
		width: 30%;
		margin: 0 1.5%;
	}
	
	.poster-item .poster-img{
		width: 100%;
		height: 150px;
	}
	.poster-item .poster-name{
		margin-top: 5px;
	}
</style>
